<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-center align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <img :src="require('@/assets/logo.png')" width="100" />
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-1">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            elevation="0"
            color="#fff"
            class="py-2 pa-4 rounded-xl"
            rounded
            :class="$style.form"
            width="360"
          >
            <v-row dense>
              <v-col cols="12">
                <v-container class="pa-0">
                  <v-row>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-sheet
                        color="#f1f2f3"
                        width="100"
                        height="100"
                        rounded
                      ></v-sheet>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <div class="text-center" :class="$style['complete-text']">
                        {{ labelComplete }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-row class="d-flex justify-center">
                  <v-col cols="10">
                    <base-btn
                      :label="labelBackToApp"
                      @click="backToApp"
                      :style="{ color: 'white' }"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import MyForm from "@/entries/front/components/form";
import ErrorList from "@/entries/front/components/error/error-list";
export default {
  components: {
    MyForm,
    ErrorList
  },
  data() {
    return {
      formKey: "signup-complete",
      title: "新規アカウント登録完了",
      input: {},
      labelComplete: `....アプリの利用を開始します`,
      labelBackToApp: "アプリに戻る"
    };
  },
  computed: {
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    entity() {
      return this.$store.getters[`auth/entity`];
    },
    errorMessages() {
      return this.$store.getters[`auth/errorMessages`];
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    backToApp() {
      const path = "/signin";
      this.$router.push({ path });
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.link {
  font-size: 16px;
  text-decoration-line: none;
  color: $base-font-color !important;
}
.error-messages {
  margin: 0;
  margin-top: 8px !important;
  padding-left: 46px !important;
  & > li {
    list-style-type: none;
    min-height: 23px;
    line-height: 12px;
    font-size: 12px;
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
  }
}
.complete-text {
  color: $auth-body-font-color;
  font-size: 15px;
  font-weight: 500;
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}
</style>
